export const photos = [
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_11.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_11.jpeg',
    thumbnailWidth: 330,
    thumbnailHeight: 218,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_12.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_12.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_13.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_13.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_14.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_14.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_16.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_16.jpeg',
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_15.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_15.jpeg',
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_17.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_17.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_18.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_18.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_19.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_19.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_23.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_23.jpeg',
    thumbnailWidth: 280,
    thumbnailHeight: 200,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_24.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_24.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_26.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_26.jpeg',
    thumbnailWidth: 300,
    thumbnailHeight: 210,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_25.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_25.jpeg',
    thumbnailWidth: 330,
    thumbnailHeight: 215,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_27.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_27.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 230,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_8.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_8.jpeg',
    thumbnailWidth: 323,
    thumbnailHeight: 212,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_9.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_9.jpeg',
    thumbnailWidth: 327,
    thumbnailHeight: 212,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_10.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_10.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_20.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_20.jpeg',
    thumbnailWidth: 327,
    thumbnailHeight: 212,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_21.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_21.jpeg',
    thumbnailWidth: 327,
    thumbnailHeight: 212,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_22.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_22.jpeg',
    thumbnailWidth: 327,
    thumbnailHeight: 212,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_3.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_3.jpeg',
    thumbnailWidth: 327,
    thumbnailHeight: 212,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_4.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_4.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_5.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_5.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_6.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_6.jpeg',
    thumbnailWidth: 327,
    thumbnailHeight: 212,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_7.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_7.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_1.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_1.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
  {
    src: 'https://storage.googleapis.com/vinhnhatwedding/fullsize/ALBUM_2.jpeg',
    thumbnail: 'https://storage.googleapis.com/vinhnhatwedding/thumbnail/ALBUM_2.jpeg',
    thumbnailWidth: 160,
    thumbnailHeight: 250,
  },
];

// {
//   // Album Sea
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836994970c1d7700934d4/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836994970c1d7700934d4/small.jpg',
//     thumbnailWidth: 320,
//   thumbnailHeight: 212,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836ba4970c1d7700934d5/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836ba4970c1d7700934d5/small.jpg',
//     thumbnailWidth: 320,
//   thumbnailHeight: 212,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841bc92ba4004ee0354a5/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841bc92ba4004ee0354a5/small.jpg',
//     thumbnailWidth: 320,
//   thumbnailHeight: 212,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841b40427f02c7007b566/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841b40427f02c7007b566/small.jpg',
//     thumbnailWidth: 320,
//   thumbnailHeight: 212,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841ac5ee34c8f6e025948/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841ac5ee34c8f6e025948/small.jpg',
//     thumbnailWidth: 320,
//   thumbnailHeight: 212,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841a5da3a0eb96207db87/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841a5da3a0eb96207db87/small.jpg',
//     thumbnailWidth: 320,
//   thumbnailHeight: 212,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841c9b7f6e7100f0dbb85/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841c9b7f6e7100f0dbb85/small.jpg',
//     thumbnailWidth: 333,
//   thumbnailHeight: 215,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648368eeef0b1dc380a7be4/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648368eeef0b1dc380a7be4/small.jpg',
//     thumbnailWidth: 199,
//   thumbnailHeight: 270,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836cc7fd5408fe305d1d7/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836cc7fd5408fe305d1d7/small.jpg',
//     thumbnailWidth: 320,
//   thumbnailHeight: 212,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648369fc15daba5be0e2cb5/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648369fc15daba5be0e2cb5/small.jpg',
//     thumbnailWidth: 300,
//   thumbnailHeight: 210,
// },
//
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836892d70c9e2af068d3c/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836892d70c9e2af068d3c/small.jpg',
//     thumbnailWidth: 160,
//   thumbnailHeight: 250,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648368103e95e00e7050375/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648368103e95e00e7050375/small.jpg',
//     thumbnailWidth: 160,
//   thumbnailHeight: 250,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836c72d70c9e2af068d3d/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836c72d70c9e2af068d3d/small.jpg',
//     thumbnailWidth: 160,
//   thumbnailHeight: 250,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836c1cb961a4e180722be/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664836c1cb961a4e180722be/small.jpg',
//     thumbnailWidth: 160,
//   thumbnailHeight: 250,
// },
// {
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841c2e154f8e78b090ad5/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664841c2e154f8e78b090ad5/small.jpg',
//     thumbnailWidth: 160,
//   thumbnailHeight: 250,
// },
// {
//   // Album Forest - 1
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664843a09acf94bd430554a5/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664843a09acf94bd430554a5/small.jpg',
//     thumbnailWidth: 160,
//   thumbnailHeight: 250,
// },
// {
//   // Album Forest - 2
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648439ada3a0eb96207db89/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648439ada3a0eb96207db89/small.jpg',
//     thumbnailWidth: 330,
//   thumbnailHeight: 212,
// },
// {
//   // Album Forest - 3
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664843947fd5408fe305d1d9/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664843947fd5408fe305d1d9/small.jpg',
//     thumbnailWidth: 330,
//   thumbnailHeight: 212,
// },
// {
//   // Album Forest - 7
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/66484379aad5a8f38b0f184f/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/66484379aad5a8f38b0f184f/small.jpg',
//     thumbnailWidth: 160,
//   thumbnailHeight: 250,
// },
// {
//   // Album Forest - 4
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648438e5dde7a766c032cc7/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648438e5dde7a766c032cc7/small.jpg',
//     thumbnailWidth: 330,
//   thumbnailHeight: 212,
// },
// {
//   // Album Forest - 5
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/66484387c947bf98e80ba9b0/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/66484387c947bf98e80ba9b0/small.jpg',
//     thumbnailWidth: 160,
//   thumbnailHeight: 250,
// },
// {
//   // Album Forest - 6
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664843802d70c9e2af068d41/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664843802d70c9e2af068d41/small.jpg',
//     thumbnailWidth: 160,
//   thumbnailHeight: 250,
// },
// {
//   // Album Cho Dam - 1
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648437303e95e00e7050383/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/6648437303e95e00e7050383/small.jpg',
//     thumbnailWidth: 330,
//   thumbnailHeight: 212,
// },
// {
//   // Album Cho Dam - 2
//   src:
//     'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664843695dde7a766c032cc6/large.jpg',
//       thumbnail:
//   'https://cdn.biihappy.com/ziiweb/website/66091681265d42dc920ec7e3/galleries/664843695dde7a766c032cc6/small.jpg',
//     thumbnailWidth: 160,
//   thumbnailHeight: 250,
// },
